<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <vuexy-logo />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title
            title-tag="h2"
            class="font-weight-bold mb-1"
          >
            Navipoint Admin Panel! 👋
          </b-card-title>
          <b-card-text class="mb-2 text-danger font-semibold">
            *Restricted access only*
          </b-card-text>

          <!-- form -->
          <validation-observer ref="loginValidation">
            <b-form
              class="auth-login-form mt-2"
              @submit.prevent
            >
              <!-- identity -->
              <b-form-group
                label="Email or Phone"
                label-for="login-email"
              >
                <validation-provider
                  #default="{ errors }"
                  name="identity"
                  rules="required|validIdentity"
                >
                  <b-form-input
                    id="login-identity"
                    v-model="userIdentity"
                    :state="errors.length > 0 ? false:null"
                    name="login-identity"
                    placeholder="Enter email or phone"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- password -->
              <b-form-group
                label="Password"
                label-for="login-password"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  rules="required"
                >
                  <b-form-input
                    id="login-password"
                    v-model="userPassword"
                    :disabled="step > 1"
                    type="password"
                    :state="errors.length > 0 ? false:null"
                    name="login-password"
                    placeholder="Enter password"
                    @keyup.enter="handleNext"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
                <div
                  v-if="step === 2"
                  class="my-1"
                >
                  <p>Where would you like to receive the OTP to login?</p>

                  <b-form-radio-group
                    id="mfaMethodSelector"
                    v-model="mfaMethod"
                    aria-describedby="Email"
                    name="mfaMethod"
                    :options="mfaMethodsAvailable"
                  />
                </div>

              </b-form-group>

              <p class="my-2 text-center">
                Forgot password ?
                <span
                  class="text-primary cursor-pointer"
                  @click="$refs['forget-password'].show()"
                >click here</span>
                to reset.
              </p>

              <!-- OTP -->
              <b-form-group
                v-if="step === 3"
                label="OTP"
                label-for="login-otp"
              >
                <validation-provider
                  #default="{ errors }"
                  name="OTP"
                  rules="required|max:6|min:6|integer"
                >
                  <b-form-input
                    id="login-otp"
                    v-model="otp"
                    type="number"
                    :state="errors.length > 0 ? false:null"
                    name="login-otp"
                    placeholder="Enter 6 digit OTP"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
                <p
                  class="w-full my-2  text-center"
                  style="font-size: 1rem; cursor: pointer;"
                >
                  Still didn't receive the OTP ?
                  <span
                    v-if="resendOTPDisabled"
                  >Please wait for {{ otpCountDown }}</span>
                  <span v-if="otpCountDown===1 && resendOTPDisabled===true"> second</span>
                  <span v-if="otpCountDown!==1 && resendOTPDisabled===true"> seconds</span>
                  <span
                    v-if="resendOTPDisabled===false"
                    class="text-primary"
                    @click="resendOTP"
                  >Resend</span>
                </p>

              </b-form-group>

              <!-- submit buttons -->
              <b-button
                type="submit"
                variant="primary"
                block
                @click="handleNext"
              >
                <span v-if="loading === false">
                  {{ step !== 3? 'Continue' : 'Sign in' }}
                </span>
                <b-spinner
                  v-else
                  class=""
                  variant="white"
                />
              </b-button>
              <b-card-text class="my-2 font-semibold">
                <div class="text-danger my-1">
                  {{ backendError }}
                </div>
                <div
                  v-if="step === 2"
                  class="my-1"
                >
                  We have sent a 6 digit OTP to your phone number
                </div>
              </b-card-text>
            </b-form>
          </validation-observer>

        </b-col>
      </b-col>
      <!-- /Login-->
      <b-modal
        ref="forget-password"
        hide-footer
        centered
        hide-header
      >
        <div
          v-if="forgetPass.step === 1"
          class="forget-password-modal"
        >
          <h1>
            Did you forget your password?
          </h1>
          <p>
            Don't worry about it! Just enter your registered phone number or email below
            and then go ahead to reset your password.
          </p>
          <validation-provider
            #default="{errors}"
            name="identity"
            mode="lazy"
            rules="validIdentity"
          >
            <b-input-group class="forgot-password-input-group d-flex flex-row flex-nowrap">
              <b-form-input
                v-model="forgetPass.userIdentity"
                type="text"
                class="phone-number"
                placeholder="Enter your phone or email"
              />
            </b-input-group>
            <small class="text-danger mt-1">{{ errors[0] }}</small>
          </validation-provider>
          <b-form-input
            v-if="forgetPass.OTPBool"
            v-model="forgetPass.OTP"
            type="text"
            class="otp otp-input w-100"
            placeholder="Enter your OTP"
          />
          <div>
            <b-button
              type="submit"
              variant="primary"
              class="button mt-2"
              @click="forgetPassword"
            >
              Next
            </b-button>
          </div>
        </div>
        <div
          v-if="forgetPass.step === 2"
          class="forget-password-modal"
        >
          <h1>
            Reset Your Password
          </h1>
          <p>
            Your password must contain at least one uppercase, one lowercase, one special character and one digit
          </p>
          <validation-observer ref="resetPasswordForm">
            <b-form autocomplete="off">
              <validation-provider
                #default="{ errors }"
                mode="lazy"
                name="password"
                rules="password"
              >
                <b-form-input
                  v-model="forgetPass.newPass"
                  type="password"
                  class="phone-number"
                  placeholder="Enter New Password"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
              <validation-provider
                v-if="forgetPass.OTPBool"
                #default="{ errors }"
                mode="lazy"
                name="new password"
                rules="confirmed:password"
              >
                <b-form-input
                  v-model="forgetPass.newPassConfirm"
                  type="password"
                  class="otp otp-input mt-2"
                  placeholder="Confirm New Password"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
              <div
                class="button"
              >
                <b-button
                  variant="primary"
                  type="button"
                  class="mt-1"
                  @click="forgetPassword"
                >
                  Submit
                </b-button>
              </div>
            </b-form>
          </validation-observer>
        </div>
      </b-modal>

    </b-row>
  </div>
</template>

<script>
import { decode } from 'jsonwebtoken'
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
// eslint-disable-next-line import/no-extraneous-dependencies
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BRow, BCol, BFormGroup, BFormInput, BCardText, BCardTitle, BForm, BButton,
} from 'bootstrap-vue'
// eslint-disable-next-line import/no-extraneous-dependencies
import { required, email } from '@validations'
import { Country } from 'country-state-city'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BCardText,
    BCardTitle,
    BForm,
    BButton,
    VuexyLogo,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      loading: false,
      mfaMethod: null,
      mfaMethodsAvailable: [],
      userIdentity: '',
      userPassword: '',
      step: 1,
      otp: null,
      required,
      email,
      // validation rulesimport store from '@/store/index'
      backendError: '',
      session: null,
      resendOTPDisabled: true,
      otpTimeout: null,
      otpCountDown: 10,
      phonePrefix: '',
      minervaID: null,
      forgetPass: {
        OTPBool: false,
        OTP: null,
        userIdentity: null,
        session: null,
        step: 1,
        sessions: null,
        newPass: null,
        newPassConfirm: null,
        error: null,
      },

    }
  },
  computed: {
    countries() {
      return Country.getAllCountries().map(coun => ({ isoCode: coun.isoCode, phonecode: coun.phonecode, searchField: coun.isoCode + coun.phonecode }))
    },

  },
  methods: {
    resendOTP() {
      this.$http.post('/user/resend-otp', {
        sessionID: this.session,
      }).then(() => {
        this.otpCountDown = 20
        this.resendOTPDisabled = true
        this.resendOTPTimer()
        this.$bvToast.toast('OTP has been sent to the registered number successfully.', {
          title: 'OTP Sent',
          variant: 'success',
          solid: true,
        })
      }).catch(err => {
        this.handleError(err)
        if (err.response) {
          const code = err.response.status
          const response = err.response.data
          if (code === 403) {
            this.$bvToast.toast(response.error, {
              title: 'OTP Failed',
              variant: 'danger',
              solid: true,
            })
          }
        }
      })
    },

    resendOTPTimer() {
      if (this.otpCountDown > 0) {
        this.otpTimeout = setTimeout(() => {
          this.otpCountDown -= 1
          this.resendOTPTimer()
        }, 1000)
      } else {
        this.otpCountDown = null
        this.resendOTPDisabled = false
      }
    },
    forgetPassword() {
      if (this.forgetPass.step === 1 && !this.forgetPass.OTPBool) {
        const requestData = {}
        if (this.forgetPass.userIdentity.includes('@')) {
          requestData.email = this.forgetPass.userIdentity
        } else {
          requestData.phone = this.forgetPass.userIdentity
        }
        this.$http
          .post('/user/forgot-password', requestData)
          .then(response => {
            this.forgetPass.sessions = response.data.session
            this.forgetPass.OTPBool = true
          })
          .catch(this.handleError)
      } else if (this.forgetPass.step === 1 && this.forgetPass.OTPBool) {
        const requestData = { sessionID: this.forgetPass.sessions }
        if (this.forgetPass.userIdentity.includes('@')) {
          requestData.emailOTP = this.forgetPass.OTP
          requestData.email = this.forgetPass.userIdentity
        } else {
          requestData.phoneOTP = this.forgetPass.OTP
          requestData.phone = this.forgetPass.userIdentity
        }

        this.$http
          .post('/user/verify-forgot-password', requestData)
          .then(response => { this.forgetPass.sessions = response.data.session; this.forgetPass.step += 1; this.minervaID = response.data.userID })
          .catch(this.handleError)
      } else {
        this.$refs.resetPasswordForm.validate().then(success => {
          if (success) {
            const requestData = { sessionID: this.forgetPass.sessions, password: this.forgetPass.newPass, minervaID: this.minervaID }
            if (this.forgetPass.userIdentity.includes('@')) {
              requestData.emailOTP = this.forgetPass.OTP
              requestData.email = this.forgetPass.userIdentity
            } else {
              requestData.phoneOTP = this.forgetPass.OTP
              requestData.phone = this.forgetPass.userIdentity
            }
            this.$http.post('/user/change-password', requestData).then(() => {
              this.forgetPass = {
                OTPBool: false,
                OTP: null,
                phone: null,
                session: null,
                step: 1,
                sessions: null,
                newPass: null,
                newPassConfirm: null,
                error: null,
              }
              this.$refs['forget-password'].hide()
            }).catch(this.handleError)
          }
        }).catch(this.handleError)
      }
    },

    validationForm() {
      this.$refs.loginValidation.validate().then(success => {
        if (success) {
          this.$router.push('/')
        }
      })
    },
    setToken(token) {
      const jwtData = decode(token)
      this.$store.commit('auth/SET_TOKEN', token)
      localStorage.setItem('adminToken', token)
      this.$http.get(`/user/${jwtData.sub}`).then(userResponse => {
        localStorage.setItem('adminData', JSON.stringify(userResponse.data))
        this.$store.commit('auth/SET_USER', userResponse.data)
        this.$router.push({ name: 'home' })
      })
    },
    handleNext() {
      this.$refs.loginValidation.validate().then(success => {
        this.loading = true
        if (success === true) {
          if (this.step === 1 || this.step === 2) {
            this.handleStepInit()
            this.resendOTPTimer()
          } else {
            this.handleStepVerify()
          }
        }
      }).catch(this.handleError).finally(() => { this.loading = false })
    },
    handleStepInit() {
      const requestData = {
        password: this.userPassword,
      }
      if (this.userIdentity.includes('@')) {
        requestData.email = this.userIdentity
      } else {
        requestData.phone = this.userIdentity
      }
      // make otp call here
      this.$http.post('/user/login', requestData, {
        params: this.mfaMethod !== null ? { mfa_method: this.mfaMethod, type: 'admin' } : { type: 'admin' },
      }).then(response => {
        if (response.data.status === 'success') {
          clearTimeout(this.otpTimeout)
          this.setToken(response.data)
        } else if (response.data.status === 'initialized') {
          this.mfaMethodsAvailable = response.data.mfaMethodsAvailable
          this.step = 2
        } else {
          this.session = response.data.session
          this.step = 3
        }
      }).catch(err => {
        if (err.response.data.error === 'please provide correct credentials') {
          this.backendError = err.response.data.error
        } else if (err.response.status === 401) {
          this.backendError = err.response.data
        } else {
          this.handleError(err)
        }
      }).finally(() => { this.loading = false })
    },
    handleStepVerify() {
      this.loading = true
      const requestData = {
        sessionID: this.session,
        password: this.userPassword,
      }

      if (this.identityType() === 'email') {
        requestData.email = this.userIdentity
      } else {
        requestData.phone = this.userIdentity
      }
      if (this.mfaMethod === 'sms') {
        // mfa method chosen as sms
        requestData.phoneOTP = this.otp
      } else {
        // mfa method chosen as email
        requestData.emailOTP = this.otp
      }

      this.$http.post('/user/verify2fa', requestData).then(({ data }) => {
        this.setToken(data.token)
      }).catch(this.handleError).finally(() => { this.loading = true })
    },
    clearErrors() {
      this.backendError = ''
    },

    identityType() {
      return this.userIdentity.includes('@') ? 'email' : 'phone'
    },
    getMfaMethod() {
      return this.mfaMethod === 'sms' ? 'phone' : 'email'
    },

  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
